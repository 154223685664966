<template>
	<div class="device-status">
		<h2 class="cm-title">设备状态</h2>
		<div class="status-modal">
			<div class="left-device-num">
				<svg class="icon" aria-hidden="true">
					<use :xlink:href="`#${countList[0].icon}`"></use>
				</svg>
				<div class="content">
					<p class="title">设备数量</p>
					<p class="data">
						{{ countList[0].value || 0 }}
						<span class="unit">{{ countList[0].unit }}</span>
					</p>
				</div>
			</div>
			<ul class="items">
				<li
					class="item"
					v-for="(item, index) in countList.slice(1)"
					:key="index"
					@click="routerLink(item.name, item.permission)"
					:style="`cursor: ${item.link ? 'pointer' : 'text'}`"
				>
					<svg class="icon" aria-hidden="true">
						<use :xlink:href="`#${item.icon}`"></use>
					</svg>
					<div class="content">
						<p class="title">{{ item.title }}</p>
						<p class="data">
							{{ item.value || 0 }}
							<span class="unit">{{ item.unit }}</span>
						</p>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { apiDeviceStatis } from '@/apis/home.api'
import { deviceDetailPermissions } from '@/config/const'
export default {
	name: 'DeviceStatus',
	data() {
		return {
			countList: [
				{
					icon: 'icon-bumenliebiao',
					title: '设备数量',
					key: 'deviceCount',
					value: '',
					unit: '台',
				},
				{
					icon: 'icon-lixianshebeishuliang',
					title: '离线设备数量',
					key: 'offlineDeviceCount',
					value: '',
					unit: '台',
					link: '/run/abnormal-records',
					permission: 'monitor:run:exception:select',
					name: 'abnormalRecords',
				},
				{
					icon: 'icon-gaojingshebeishuliang',
					title: '告警设备数量',
					key: 'alarmDeviceCount',
					value: '',
					unit: '台',
					link: '/run/alarm-device',
					permission: 'monitor:run:alarm-device:select',
					name: 'alarmDevice',
				},
				{
					icon: 'icon-guzhangshebeishuliang',
					title: '故障设备数量',
					key: 'faultyDeviceCount',
					value: '',
					unit: '台',
					link: '/run/alarm-records',
					permission: 'monitor:run:alarm-record:select',
					name: 'alarmRecords',
				},
				{
					icon: 'icon-chaoshiyongnianxian',
					title: '超使用年限数量',
					key: 'overdueDeviceCount',
					value: '',
					unit: '台',
					link: '/run/timeout-device',
					permission: deviceDetailPermissions,
					name: 'timeoutDevice',
				},
				{
					icon: 'icon-shebeizaixianshuai',
					title: '设备在线率',
					key: 'deviceOnlineRatio',
					value: '',
				},
				{
					icon: 'icon-zaixianshuliang',
					title: '在线设备数量',
					key: 'onlineDeviceCount',
					value: '',
					unit: '台',
				},
			],
			firstCategory: null,
		}
	},
	methods: {
		getDeviceStatis(firstCategory) {
			const params = {}
			if (firstCategory) {
				params.firstCategory = firstCategory
			}
			this.firstCategory = firstCategory || null
			apiDeviceStatis(params)
				.then(res => {
					this.countList = this.countList.map(item => {
						return {
							...item,
							value: item.key === 'deviceOnlineRatio' ? `${res[item.key]}%` : res[item.key],
						}
					})
				})
				.catch(err => {
					console.error(err)
				})
		},

		routerLink(name, permission) {
			if (name) {
				if (!this.$has(permission)) {
					this.$message.error('您没有操作权限！')
					return
				}
				return this.$router.push({
					name,
					params: {
						alarmType: '1',
						firstCategory: this.firstCategory,
						update: name !== 'alarmRecords',
					},
				})
			}
		},
	},
}
</script>

<style scoped lang="scss">
.device-status {
	margin-top: 30px;
	margin-left: 5px;
}
.status-modal {
	display: flex;
	.left-device-num {
		width: 160px;
		background: rgb(223, 228, 255);
		border-radius: 12px;
		margin-top: 26px;
		margin-right: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 20px 0;
		margin-bottom: 2px;
		.icon {
			width: 30px;
			height: 30px;
			border-radius: 12px;
			margin-left: 18px;
		}
		.content {
			align-items: center;
			margin-left: 18px;
			.title {
				margin-top: 5px;
				color: #333;
				white-space: nowrap;
				opacity: 0.7;
			}
			.data {
				margin-top: 12px;
				font-size: 24px;
				font-family: DINAlternate-Bold, DINAlternate;
				font-weight: bold;
				color: #333;
				line-height: 28px;
				letter-spacing: 1px;
				overflow: hidden;
				white-space: nowrap;
				@media (max-width: 1680px) {
					margin-top: 8px;
					font-size: 20px;
				}
				.unit {
					font-size: 16px;
					font-family: SourceHanSansCN-Medium, SourceHanSansCN;
					font-weight: 600;
					line-height: 24px;
				}
			}
		}
	}
}
.items {
	display: flex;
	flex-wrap: wrap;
	flex: 1;
	width: 0;
	.item {
		margin-top: 26px;
		width: 33.3%;
		display: flex;
		padding-right: 20px;
		.icon {
			flex: 0 0 60px;
			height: 60px;
			border-radius: 12px;
		}
		@media (max-width: 1680px) {
			width: 50%;
			.icon {
				flex: 0 0 50px;
				height: 50px;
			}
		}
		.content {
			flex: 1;
			align-items: center;
			margin-left: 26px;
			.title {
				margin-top: 5px;
				color: #333;
				white-space: nowrap;
				opacity: 0.7;
			}
			.data {
				margin-top: 12px;
				font-size: 24px;
				font-family: DINAlternate-Bold, DINAlternate;
				font-weight: bold;
				color: #333;
				line-height: 28px;
				letter-spacing: 1px;
				overflow: hidden;
				white-space: nowrap;
				@media (max-width: 1680px) {
					margin-top: 8px;
					font-size: 20px;
				}
				.unit {
					font-size: 16px;
					font-family: SourceHanSansCN-Medium, SourceHanSansCN;
					font-weight: 600;
					line-height: 24px;
				}
			}
		}
	}
}
</style>
