<template>
	<div id="lineChart" class="line-chart" />
</template>
<script>
const echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/line')
require('echarts/lib/component/grid')
require('echarts/lib/component/tooltip')
import { insertStr } from '@/utils'

export default {
	name: 'LineChart',
	props: {
		chartData: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			options: {
				grid: {
					left: '50px',
					right: '0%',
					top: '40px',
					bottom: '20px',
				},
				xAxis: {
					type: 'category',
					data: [],
					axisTick: { show: false },
					axisLine: { lineStyle: { color: '#EBECF0' } },
					axisLabel: {
						textStyle: { color: '#666' },
						formatter: value => {
							const [year, month] = value.split('-')
							return month == 1 ? `${year}.${month}月` : `${month}月`
						},
					},
				},
				yAxis: {
					type: 'value',
					name: '告警数量',
					nameTextStyle: { align: 'right' },
					nameGap: 20,
					axisLabel: {
						textStyle: {
							align: 'right',
							baseline: 'middle',
						},
						formatter: value => {
							if (value) return value.toFixed(0)
							return 0
						},
					},
					minInterval: 1,
					splitLine: {
						lineStyle: {
							color: '#EBECF0',
						},
					},
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						lineStyle: {
							opacity: 0,
						},
					},
				},
				series: [
					{
						type: 'line',
						data: [],
						smooth: true,
						showSymbol: false,
						symbolSize: 8,
						emphasis: {
							itemStyle: {
								color: '#fff',
								borderColor: '#47E4E6',
								borderWidth: 3,
							},
						},
						lineStyle: {
							width: 6,
							color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
								{ offset: 0, color: '#37E6C8' },
								{ offset: 0.1, color: '#37E6C8' },
								{ offset: 0.2, color: '#35CCA4' },
								{ offset: 0.5, color: '#47E4E6' },
								{ offset: 0.8, color: '#3BC1FF' },
								{ offset: 0.9, color: '#37E6C8' },
								{ offset: 1, color: '#37E6C8' },
							]),
						},
					},
				],
			},
		}
	},
	watch: {
		chartData: {
			handler() {
				this.updateChart()
			},
			deep: true,
		},
	},
	methods: {
		updateChart() {
			const chartDom = document.getElementById('lineChart')
			const lineChart = echarts.init(chartDom)
			this.options.xAxis.data = this.chartData.map(item => insertStr(item.date, 4, '-'))
			this.options.series[0].data = this.chartData.map(item => item.count)
			lineChart.clear()
			lineChart.setOption(this.options)
			addEventListener('resize', () => {
				lineChart.resize()
			})
		},
	},
}
</script>
<style lang="scss" scoped></style>
