<template>
	<div class="box-alarm">
		<h2 class="cm-title">重点告警汇总{{ title }}</h2>
		<ul class="items" v-if="highAlarms.length">
			<li
				class="item"
				@click="linkAlarmRecords(item.classification)"
				v-for="(item, index) in highAlarms"
				:key="index"
			>
				<svg class="icon" aria-hidden="true">
					<use :xlink:href="`#${item.icon}`"></use>
				</svg>
				<span class="title">{{ item.alarmName }}</span>
				<span class="number">{{ item.count }}</span>
			</li>
		</ul>
		<gc-empty v-else />
		<h2 class="cm-title chart">告警半年度汇总</h2>
		<line-chart v-show="!isAllZero" class="chart-container" :chart-data="chartData" />
		<gc-empty v-show="isAllZero" />
	</div>
</template>

<script>
import LineChart from './LineChart.vue'
import { apiHighAlarmCount, apiHalfAlarmCount } from '@/apis/home.api'

// key对应告警项id
const IconMap = {
	1: 'icon-shebeiguzhanggaojing',
	2: 'icon-liulianggaojing1',
	3: 'icon-yaligaojing1',
	4: 'icon-wendugaojing1',
	5: 'icon-xielougaojing',
	6: 'icon-didianyagaojing1',
	7: 'icon-famenguanbigaojing',
	8: 'icon-shebeiyichanggaojing',
}

export default {
	name: 'AlarmStatis',
	props: {
		title: String,
	},
	components: {
		LineChart,
	},
	data() {
		return {
			highAlarms: [],
			chartData: [],
			isAllZero: true, // 半年度汇总数据全为0
			firstCategory: null,
		}
	},
	methods: {
		getAlarmStatis(firstCategory) {
			const params = {}
			if (firstCategory) {
				params.firstCategory = firstCategory
			}
			this.firstCategory = firstCategory || null
			apiHighAlarmCount(params)
				.then(({ records = [] }) => {
					this.highAlarms = records.map(item => {
						return {
							...item,
							icon: IconMap[item.classification],
						}
					})
				})
				.catch(err => {
					console.error(err)
				})

			apiHalfAlarmCount(params)
				.then(({ records = [] }) => {
					this.isAllZero = records.every(item => !item.count)
					this.chartData = records
				})
				.catch(err => {
					console.error(err)
				})
		},

		linkAlarmRecords(alarmType) {
			if (!this.$has('monitor:run:alarm-record:select')) {
				this.$message.error('您没有操作权限！')
				return
			}
			this.$router.push({
				name: 'alarmRecords',
				params: {
					alarmType: String(alarmType),
					firstCategory: this.firstCategory,
				},
			})
		},
	},
}
</script>

<style scoped lang="scss">
.box-alarm {
	margin-top: 30px;
	width: 100%;
	min-height: calc(100% - 194px);
	background: #f2f4fc;
	border-radius: 16px;
	padding: 20px 24px;
	display: flex;
	flex-direction: column;
	.items {
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;
		position: relative;
		&::before {
			content: '';
			position: absolute;
			left: 50%;
			bottom: 15px;
			width: 1px;
			height: calc(100% - 50px);
			border-left: 1px dashed #9ea5c5;
		}
		.item {
			margin-top: 20px;
			width: 50%;
			display: flex;
			align-items: center;
			cursor: pointer;
			&:nth-of-type(2n + 1) {
				padding-right: 8%;
			}
			&:nth-of-type(2n) {
				padding-left: 8%;
			}
			.icon {
				flex: 0 0 46px;
				height: 46px;
				border-radius: 6px;
			}
			.title {
				flex: 1;
				margin-left: 24px;
				font-size: 18px;
				font-weight: 500;
				color: #333;
				white-space: nowrap;
			}
			.number {
				min-width: 40px;
				font-size: 20px;
				font-family: DINAlternate-Bold, DINAlternate;
				font-weight: bold;
				color: #333;
				line-height: 24px;
				letter-spacing: 1px;
			}
		}
	}
	.cm-title.chart {
		margin-top: 30px;
	}
	.chart-container {
		flex: 1;
		min-height: 200px;
		max-height: 300px;
		padding-top: 20px;
	}
}
@media (max-width: 1680px) {
	.box-alarm .items {
		.item {
			&:nth-of-type(2n + 1) {
				padding-right: 5%;
			}
			&:nth-of-type(2n) {
				padding-left: 5%;
			}
			.icon {
				flex: 0 0 40px;
				height: 40px;
			}
			.title {
				margin-left: 5%;
				font-size: 16px;
			}
		}
	}
}
</style>
